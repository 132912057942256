<script>
	import Button from '@smui/button';
	import List, { Item, Meta, Text } from '@smui/list';

	import Container from '../components/Container';
	import { signIn, user } from '../stores/auth';
	import { loading, notes } from '../stores/notes';
	// console.log($notes);
</script>

<Container title="All Notes">
	{#if !$user && !$loading}
		<List nonInteractive>
			<Item>
				<Text>Hello and welcome to NoteMe</Text>
			</Item>
			<Item>
				<Text>Please sign in below to be able to store and edit your markdown notes from any device</Text>
			</Item>
			<Item>
				<Button variant="raised" on:click={signIn}>
					Sign in with Google
				</Button>
			</Item>
		</List>
	{:else}
		<List>
			{#each $notes as note}
				<li>
					<a class="mdc-list-item" href={`/note/${note.id}`}>
						<Text>{note.title}</Text>
						<Meta class="material-icons">keyboard_arrow_right</Meta>
					</a>
				</li>
			{:else}
				{#if $loading}
					<Item>
						<Text>Loading, please wait while we gather your notes</Text>
					</Item>
				{:else}
					<Item>
						<Text>No notes found</Text>
					</Item>
				{/if}
			{/each}
		</List>
	{/if}
</Container>
