<script>
	import { router } from 'tinro';
	import IconButton from '@smui/icon-button';
	import TopAppBar, { FixedAdjust, Row, Section, Title } from '@smui/top-app-bar';
	import H1 from '@smui/common/H1.svelte';

	const Adjust = FixedAdjust;
	const backPath = $router.path !== $router.from ? $router.from : '/';

	export let title = '';
</script>

<TopAppBar variant="fixed" color="primary">
	<Row>
		<Section>
			<slot name="icons-start">
				<a href={backPath}>
					<IconButton class="material-icons">arrow_back</IconButton>
				</a>
			</slot>
			<Title component={H1}>{title}</Title>
		</Section>
		<Section align="end" toolbar>
			<slot name="icons-end"></slot>
		</Section>
	</Row>
</TopAppBar>

<div use:Adjust />
