import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';

const config = {
	apiKey: DOTENV_FIREBASE_API_KEY,
	authDomain: DOTENV_FIREBASE_AUTH_DOMAIN,
	databaseURL: DOTENV_FIREBASE_DATABASE_URL,
	projectId: DOTENV_FIREBASE_PROJECT_ID,
	storageBucket: DOTENV_FIREBASE_STORAGE_BUCKET,
	messagingSenderId: DOTENV_FIREBASE_MESSAGING_SENDER_ID,
};

firebase.initializeApp(config);

// This enables offline support
// Firebase is smart enough to update the changes when the user
// goes online again
firebase.firestore()
	.enablePersistence()
	.catch((error) => {
		if (error.code === 'failed-precondition') {
			// Multiple tabs open, persistence can only be enabled
			// in one tab at a a time.
			const event = new Event('firebasePersistenceFailedPrecondition');
			window.dispatchEvent(event);
		} else if (error.code === 'unimplemented') {
			// The current browser does not support all of the
			// features required to enable persistence
			const event = new Event('firebasePersistenceUnimplemented');
			window.dispatchEvent(event);
		}
	});

const auth = firebase.auth();
const firestore = firebase.firestore();
const provider = new firebase.auth.GoogleAuthProvider();

export {
	auth,
	firestore,
	provider
}
