import { writable } from 'svelte/store';

import { user as authUser } from './auth';
import { firestore } from '../firebase';

import { getTitle } from '../utils';

let user;
const labels = writable([]);
const loading = writable(true);
const notes = writable([]);

/**
 * Create a note based on the text provided, and navigate to the new note.
 * If an Untitled note already exists, navigate to that note
 * @param {string} [text] - Initial text to use for the note
 */
// const addNote = (text = '') => {
// 	const untitledNote = $notes.find((note) => note.text === '');

// 	if (untitledNote) {
// 		history.push({
// 			pathname: `/note/${untitledNote.id}`,
// 			state: { modal: true },
// 		});
// 	} else {
// 		const newNote = firestore.collection(user.uid).doc();
// 		const value = {
// 			created: +new Date(),
// 			date: +new Date(),
// 			favourite: false,
// 			id: newNote.id,
// 			text,
// 			title: getTitle(text),
// 		};

// 		newNote.set(value).then(() => history.push({
// 			pathname: `/note/${value.id}`,
// 			state: { modal: true },
// 		}));
// 	}
// };

/**
 * Delete a note and navigate back to Home
 * @param {object} [note]
 */
// const deleteNote = (note) => {
// 	firestore.collection(user.uid).doc(note.id).delete();
// 	history.replace('/');

// 	snackbar.showMessage({
// 		message: `"${note.title}" has been deleted`,
// 		actionText: 'Undo',
// 		actionFunction() {
// 			firestore.collection(user.uid).doc(note.id).set(note);
// 		},
// 	});
// };

/**
 * Toggle favourite on a note
 * @param {object} [note]
 */
// const favouriteNote = (note) => {
// 	firestore.collection(user.uid).doc(note.id).update({
// 		favourite: !note.favourite,
// 	});
// };

/**
 * Update the labels on a note
 * @param {array} newLabels
 * @param {object} [note]
 */
// const updateLabels = (newLabels, note) => {
// 	firestore.collection(user.uid).doc(note.id).update({
// 		labels: newLabels,
// 	});
// };

/**
 * Update the text on a note
 * @param {string} text
 * @param {object} [note]
 */
const updateNote = (text, note) => {
	firestore.collection(user.uid).doc(note.id).update({
		date: +new Date(),
		text,
		title: getTitle(text),
	});
};


authUser.subscribe(value => {
	user = value;
	if (user) {
		firestore.collection(user.uid)
			.onSnapshot((snapshot) => {
				const authNotes = snapshot.docs.map((doc) => doc.data());
				const authLabels = authNotes.map((note) => note.labels).filter(Boolean).flat();

				loading.set(false);
				notes.set(authNotes);
				labels.set([...new Set(authLabels)].sort());
			});
	} else if (user !== null) {
		loading.set(false);
		labels.set([]);
		notes.set([]);
	}
})


export {
	// addNote,
	// deleteNote,
	// favouriteNote,
	labels,
	loading,
	notes,
	updateNote,
	// updateLabels,
};
