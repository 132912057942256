<script>
	import { router } from 'tinro';
	import Fab, { Icon } from '@smui/fab';

	import Page from '../components/Page';
	import { notes, updateNote } from '../stores/notes';

	const { params: { id } } = router.meta();
	let changedText = '';
	let edit = false;

	$: currentNote = $notes.find((note) => note.id === id);
	$: if (changedText !== '') {
		console.log(changedText);
		updateNote(changedText, currentNote);
		changedText = '';
	}
</script>

<style>
	.content {
		width: 100%;
		flex-grow: 1;
		padding: 16px;
		padding-bottom: 86px;
		overflow-y: auto;
	}

	.textarea {
		resize: none;
		border: none;
		font-size: 1.2em;
	}
	.textarea:focus {
		outline: none;
	}

	:global(.fab) {
		position: absolute;
		bottom: 16px;
		right: 16px;
	}
</style>

<Page title={currentNote?.title || 'Note'}>
	{#if currentNote}
		{#if edit}
			<textarea
				class="content textarea"
				value={currentNote.text}
				on:change={(event) => changedText = event.target.value}
			/>
		{:else}
			<span>{currentNote.text}</span>
		{/if}

		<Fab class="fab" color="primary" on:click={() => edit = !edit}>
			<Icon class="material-icons">{edit ? 'save' : 'edit'}</Icon>
		</Fab>
	{:else}
		<span>Loading note. Please wait</span>
	{/if}
</Page>
