<script>
	import { onDestroy } from 'svelte';
	import Button from '@smui/button';
	import IconButton from '@smui/icon-button';
	import { Item, Meta, Text } from '@smui/list';

	import { updateAvailable } from '../stores/settings';

	let loading = false;
	let timer;

	const updateServiceWorker = () => {
		if ('serviceWorker' in navigator) {
			navigator.serviceWorker.ready.then((registration) => registration.update());
		} else {
			setTimeout(() => window.location.reload(window.location.href), 1500);
		}
	};

	const handleButtonClick = () => {
		if ($updateAvailable) {
			window.location.reload();
		} else if (!loading) {
			loading = true;
			updateServiceWorker();
			timer = setTimeout(() => loading = false, 2000);
		}
	};

	onDestroy(() => clearTimeout(timer));
</script>

<style>
	.loading {
		display: block;
		animation: spin infinite 1s linear;
	}

	@keyframes spin {
		from { transform: rotate(0deg); }
		to { transform: rotate(360deg); }
	}
</style>

<Item>
	<Text>Check for update</Text>
	<Meta class="icon-edge">
		{#if $updateAvailable}
			<Button variant="raised" on:click={handleButtonClick}>
				Update
			</Button>
		{:else}
			<span class:loading={loading}>
				<IconButton
					class="material-icons"
					on:click={handleButtonClick}
				>refresh</IconButton>
			</span>
		{/if}
	</Meta>
</Item>
