import { writable } from 'svelte/store';

import { auth, provider } from '../firebase';

const user = writable(null);

const signIn = () => auth
	.signInWithPopup(provider)
	.then(({ user: authUser }) => user.set(authUser));

const signOut = () => auth
	.signOut()
	.then(() => user.set(false));

auth.onAuthStateChanged((authUser) => {
	if (authUser) {
		user.set(authUser);
	} else {
		user.set(false);
	}
});

export {
	// isSignedIn: Boolean(user),
	signIn,
	signOut,
	user,
}
