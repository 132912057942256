<script>
	import AppBar from './AppBar';

	export let title = '';
</script>

<style>
	:global(.content) {
		flex-grow: 1;
		overflow-x: hidden;
		overflow-y: auto;
	}
</style>

<svelte:head>
	<title>{title} | NoteMe</title>
</svelte:head>

<AppBar title={title} />

<main class="content">
	<slot />
</main>
