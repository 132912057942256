import App from './App.svelte';
import * as registerServiceWorker from './registerServiceWorker';

const app = new App({
	target: document.body,
});

registerServiceWorker.register();

export default app;
