import { Workbox } from 'workbox-window';

export const register = () => {
	if (DOTENV_ENVIRONMENT === 'production' && 'serviceWorker' in navigator) {
		const wb = new Workbox('/service-worker.js');

		wb.addEventListener('installed', (event) => {
			if (navigator.serviceWorker.controller) {
				// New Content Available event
				const event = new Event('swNewContentAvailable');
				window.dispatchEvent(event);
			} else {
				// Content Cached event
				const event = new Event('swContentCached');
				window.dispatchEvent(event);
			}
		});

		wb.register();
	}
}
