<script>
	import { Item, Text } from '@smui/list';
</script>

<style>
	.meta {
		margin-left: auto;
		margin-right: 0;
		font-size: 95%;
	}
</style>

<Item>
	<Text>App Version</Text>
	<span class="meta">{DOTENV_APP_VERSION}</span>
</Item>
