<script>
	import Drawer, {
		AppContent,
		Content,
		Header,
		Title,
		Subtitle,
		Scrim,
	} from '@smui/drawer';
	import IconButton from '@smui/icon-button';

	import AppBar from './AppBar';
	import DrawerContents from './DrawerContents';

	let myDrawer;
	let myDrawerOpen = false;
	export let title = '';
</script>

<style>
	:global(.container) {
		width: 100%;
		display: flex;
		position: relative;
		flex-grow: 1;
		overflow-x: hidden;
		flex-direction: column;
	}

	:global(.content) {
		flex-grow: 1;
		overflow-x: hidden;
		overflow-y: auto;
	}
</style>

<svelte:head>
	<title>{title} | NoteMe</title>
</svelte:head>

<Drawer variant="modal" bind:this={myDrawer} bind:open={myDrawerOpen}>
	<Header>
		<Title>NoteMe</Title>
		<Subtitle>{DOTENV_APP_VERSION}</Subtitle>
	</Header>
	<Content>
		<DrawerContents />
	</Content>
</Drawer>

<Scrim />

<AppContent class="container">
	<AppBar title={title}>
		<span slot="icons-start">
			<IconButton class="material-icons" on:click={() => myDrawerOpen = !myDrawerOpen}>
				menu
			</IconButton>
		</span>
		<span slot="icons-end">
			<IconButton class="material-icons" aria-label="Add Note">
				add
			</IconButton>
			<IconButton class="material-icons" aria-label="Search Notes">
				search
			</IconButton>
		</span>
	</AppBar>
	<main class="content">
		<slot />
	</main>
</AppContent>
