<script>
		import IconButton from '@smui/icon-button';
		import List, {
			Graphic,
			Item,
			Meta,
			PrimaryText,
			SecondaryText,
			Text,
		} from '@smui/list';

		import { signIn, signOut, user } from '../stores/auth';
</script>

<style>
	:global(.icon-edge) {
		margin-right: -12px;
	}
</style>

{#if $user}
	<List twoLine avatarList nonInteractive>
		<Item>
			<Graphic style="background-image: url({$user.photoURL});background-size: 100%;" />
			<Text>
				<PrimaryText>{$user.displayName}</PrimaryText>
				<SecondaryText>{$user.email}</SecondaryText>
			</Text>
			<Meta class="icon-edge">
				<IconButton class="material-icons" on:click={signOut}>exit_to_app</IconButton>
			</Meta>
		</Item>
	</List>
{:else}
	<List twoLine avatarList>
		<Item on:SMUI:action={signIn}>
			<Graphic style="background-image: url(https://via.placeholder.com/40x40.png?text=N);" />
			<Text>
				<PrimaryText>Sign In</PrimaryText>
				<SecondaryText>Using your Google Account</SecondaryText>
			</Text>
		</Item>
	</List>
{/if}
