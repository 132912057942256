<script>
	import List, {
		Item,
		Text,
		Graphic,
		Separator,
		Subheader,
	} from '@smui/list';
	import H6 from '@smui/common/H6.svelte';

	import { labels } from '../stores/notes';
</script>

<style>
	:global(.mdc-drawer__content) {
		display: flex;
		flex-direction: column;
	}
	:global(.mdc-drawer__content__list) {
		flex-grow: 1;
		overflow-x: hidden;
		overflow-y: auto;
	}
</style>

<List class="mdc-drawer__content__list">
	<Item activated>
		<Graphic class="material-icons" aria-hidden="true">edit</Graphic>
		<Text>All Notes</Text>
	</Item>

	<Separator nav />

	<Subheader component={H6}>Labels</Subheader>
	{#each $labels as label}
		<Item>
			<Graphic class="material-icons" aria-hidden="true">label</Graphic>
			<Text>{label}</Text>
		</Item>
	{:else}
		<Item>
			<Text>No Labels</Text>
		</Item>
	{/each}
</List>
<List>
	<li>
		<a class="mdc-list-item" href="/settings/">
			<Graphic class="material-icons" aria-hidden="true">settings</Graphic>
			<Text>Settings</Text>
		</a>
	</li>
</List>
